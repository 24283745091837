const Work = () => {
  return (
    <section className="px-3 py-20 flex flex-col items-center">
      <img
        src="img/statehouse_logo_horizontal.png"
        alt="Statehouse App"
        className="mb-3 max-w-full w-48"
      />
      <p className=" w-96 mx-auto max-w-full text-center font-body font-thin">
        Statehouse App is a simple legislative campaign management tool for easy
        legislative tracking and collaboration. It is available on iOS and
        Android devices.
      </p>
      <div className="flex justify-center py-3 mt-3">
        <a
          href="https://statehouseapp.com"
          target="_blank"
          rel="noreferrer"
          className="bg-orange text-white px-8 py-2 rounded-lg font-body font-light"
        >
          Check It Out
        </a>
      </div>
    </section>
  );
};

export default Work;
